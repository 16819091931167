define("neon-editor/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uz2zKE6+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"outlet-wrapper\"],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "neon-editor/templates/application.hbs"
    }
  });

  _exports.default = _default;
});