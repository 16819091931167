define("neon-editor/helpers/words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function words([string]) {
    let wordsCount;

    if (string === undefined || string === '') {
      wordsCount = 0;
    } else {
      wordsCount = string.trim().split(/\s+/).length;
    }

    return `words: ${wordsCount}`;
  }

  var _default = Ember.Helper.helper(words);

  _exports.default = _default;
});