define("neon-editor/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vow+k7Xr",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"editor\",[],[[\"@value\",\"@selectedColor\",\"@isFullScreen\"],[[32,1,[\"text\"]],[32,1,[\"selectedColor\"]],[32,1,[\"isFullScreen\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"about\"],[12],[2,\"A neon plain text editor ✨\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "neon-editor/templates/index.hbs"
    }
  });

  _exports.default = _default;
});