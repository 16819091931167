define("neon-editor/helpers/concatEditorData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function concatEditorData([string]) {
    let wordsCount;
    let charCount;
    let linesCount;

    if (string === undefined || string === '') {
      wordsCount = 0;
      charCount = 0;
      linesCount = 0;
    } else {
      wordsCount = string.trim().split(/\s+/).length;
      charCount = string.length;
      linesCount = string.split(/\r*\n/).length;
    }

    return `w:${wordsCount} / c:${charCount} / l:${linesCount}`;
  }

  var _default = Ember.Helper.helper(concatEditorData);

  _exports.default = _default;
});