define("neon-editor/components/editor/key-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="key-data">
  	<div class="data desktop">
  		{{words @value}}
  	</div>
  	<div class="data desktop">
  		{{chars @value}}
  	</div>
  	<div class="data desktop">
  		{{lines @value}}
  	</div>
  	<div class="data mobile">
  		{{concatEditorData @value}}
  	</div>
  	<a class="github" href="https://github.com/adamfuhrer/neon-editor" target="_blank" title="GitHub repo">
  		{{svg-jar "github" width="18px" height="18px"}}   
  	</a>
  </div>
  */
  {"id":"czs0jM8Z","block":"{\"symbols\":[\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"key-data\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"data desktop\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"data desktop\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[32,1]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"data desktop\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[[32,1]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"data mobile\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[[32,1]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"a\"],[14,0,\"github\"],[14,6,\"https://github.com/adamfuhrer/neon-editor\"],[14,\"target\",\"_blank\"],[14,\"title\",\"GitHub repo\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,4],[\"github\"],[[\"width\",\"height\"],[\"18px\",\"18px\"]]]],[2,\"   \\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"words\",\"chars\",\"lines\",\"concatEditorData\",\"svg-jar\"]}","meta":{"moduleName":"neon-editor/components/editor/key-data.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});