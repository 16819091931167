define("neon-editor/helpers/lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function lines([string]) {
    let linesCount;

    if (string === undefined || string === '') {
      linesCount = 0;
    } else {
      linesCount = string.split(/\r*\n/).length;
    }

    return `lines: ${linesCount}`;
  }

  var _default = Ember.Helper.helper(lines);

  _exports.default = _default;
});