define("neon-editor/helpers/chars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function chars([string]) {
    let charCount;

    if (string === undefined) {
      charCount = 0;
    } else {
      charCount = string.length;
    }

    return `characters: ${charCount}`;
  }

  var _default = Ember.Helper.helper(chars);

  _exports.default = _default;
});