define("neon-editor/components/editor/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="color-picker" data-test-color-picker>
    {{#each @colors as |color|}}
      <button class="color {{concat color (if (eq color @selectedColor) ' selected')}}"
              {{on "click" (fn @onColorChange color)}}
              data-test-button-color={{color}}>
      </button>
    {{/each}}
  </div>
  */
  {"id":"BtxoH12p","block":"{\"symbols\":[\"color\",\"@selectedColor\",\"@onColorChange\",\"@colors\"],\"statements\":[[10,\"div\"],[14,0,\"color-picker\"],[14,\"data-test-color-picker\",\"\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"color \",[30,[36,2],[[32,1],[30,[36,1],[[30,[36,0],[[32,1],[32,2]],null],\" selected\"],null]],null]]]],[16,\"data-test-button-color\",[32,1]],[4,[38,4],[\"click\",[30,[36,3],[[32,3],[32,1]],null]],null],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"concat\",\"fn\",\"on\",\"-track-array\",\"each\"]}","meta":{"moduleName":"neon-editor/components/editor/color-picker.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});